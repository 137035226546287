<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
// import InlineSvg from "vue-inline-svg";
export default {
  setup() {
    const route                 = useRoute();
    const store                 = useStore();
    const categories            = computed(() => store.state.indicesList);
    const category              = computed( () => store.getters.getCategory(route.params.category));

    const appSection            = process.env.VUE_APP_SECTION_NAME;
    const appSectionButtonText  = process.env.VUE_APP_SECTION_BUTTON_TEXT;
    const appSectionButtonUrl   = process.env.VUE_APP_SECTION_BUTTON_URL;


    console.log('appSection', appSection);
    console.log('appSectionButtonText', appSectionButtonText);
    console.log('appSectionButtonUrl', appSectionButtonUrl);

    const getGraphsNum = indice => {
      let graphs = JSON.parse(indice.graphs);
      return graphs.length;
    }

    const getItems = (category, parent) => {
      return parent.indices.filter(d => d.category_id == category.id);
    }

    return {
      category,
      // homeIndex,
      categories,
      // indices,
      getGraphsNum,
      getItems,

      appSection,
      appSectionButtonText,
      appSectionButtonUrl
    };
  },
  //   components: { InlineSvg },
};
</script>
<template>
  <!-- breadcrumb -->
  <div class="breadcrumb">
    <div class="container">
      <ul>
        <li>
          <!-- <a href="{{url('')}}">Inicio</a> -->
          <router-link :to="{ name: 'Home'}">Inicio</router-link>
        </li>
        <li>
          <!-- <a href="{{url('/indicadores')}}">Indicadores</a> -->
          <router-link :to="{ name: 'Categories'}">Estructura</router-link>
          </li>
        <li v-if="category">{{category.name}}</li>
      </ul>
    </div>
  </div>

  <section class="pb-4" v-if="category">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <h1 class="mb-4">{{category.name}}</h1>
        </div>
        <div class="col-6">
          <p>{{category.categories.length}} {{category.categories.length == 1 ? 'categoría' : 'categorías'}}  | {{category.indices.length}} {{category.indices.length == 1 ? 'indicador' : 'indicadores'}} </p>
         </div>
         <div class="col-6 text-right">
          <a v-if="category.name.toLowerCase() == appSection" :href="appSectionButtonUrl" class="sg_btn_cat_alt mr-4">
            {{ appSectionButtonText }}
          </a>
          <router-link :to="{ name: 'Categories'}" class="sg_btn_cat"> Más módulos y componentes [+] </router-link>
        </div>
      </div>
    </div>
  </section>
  <section class="sg_subcontent" v-if="category">
    <div class="container">
      <!-- indicador-->
      <div class="row box">
        <div class="col-12">
        <div class="row">
          <div class="col-sm-10 offset-sm-1">
           
            
           <!-- <p>
           
              En la siguiente tabla solo se muestra el último año disponible,
              ara revisar los datos completos del indicador da clic sobre el
              nombre del indicador.
            </p>-->
            <div v-if="category.indices.length && category.categories.length">

              <div  v-for="indicador of category.categories" :key="`cat-item-${indicador.id}`">
                <h4 class="mt-4 pt-4"><strong>Categoría:</strong> {{indicador.name}} </h4>
                
                

              
            
            <table class="table pt-0 mt-0">
              <thead>
                <tr>
                  <th>Indicador</th>
                  <th class="text-right">Gráficas</th>
                  <th>Ver</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="index of getItems(indicador, category)" :key="`indicador-${indicador.id}-${index.id}`">
                  <td>
                    <router-link :to="{ name: 'Indice', params: { category: category.slug, indice : index.slug }}" class="sg_t_link">{{index.title}}</router-link>
                  </td>
                  
                  <td class="text-right"> {{getGraphsNum(index)}} <b class="sg_graph_i"></b></td> 
                  <td>
                    <router-link :to="{ name: 'Indice', params: { category: category.slug, indice : index.slug }}" class="sg_btn_cat">Ver indicador</router-link>
            
                  </td>
                </tr>
               
              </tbody>
            </table>
            </div>
            </div>
            <div v-else>
              <h4 class="mb-4">Por ahora no existen indicadores para esta módulo.</h4>
              <p>Estamos trabajando para actualizar los indicadores tan pronto como sea posible.</p>
            </div>
            
            </div>
          </div>
        </div>
      </div>
      <!-- indicador ends-->
    </div>
  </section>
</template>